'use strict';
export const MARGIN = 99;
export const Constants = Object.freeze({
  OPERATOR_SETTINGS: [
    {
      title: 'Manage Feasibility',
      key: 'MANAGE_FEASIBILITY',
      active: false,
    },
    {
      title: 'Margin settings',
      key: 'MARGIN_SETTINGS',
      active: false,
    },
    {
      title: 'Suppliers settings',
      key: 'SUPPLIERS_SETTINGS',
      active: false,
    },
    {
      title: 'Currency settings',
      key: 'CURRENCY_SETTINGS',
      active: false,
    },
    {
      title: 'Global settings',
      key: 'GLOBAL_SETTINGS',
      active: false,
    },
    {
      title: 'Config settings',
      key: 'CONFIG_SETTINGS',
      active: false,
    },
    {
      title: 'Samplify mappings',
      key: 'SAMPLIFY_MAPPINGS',
      active: false,
    },
    {
      title: 'Genpop settings',
      key: 'GENPOP_SETTINGS',
      active: false,
    },
    {
      title: 'Manage census',
      key: 'MANAGE_CENSUS',
      active: false,
    },
    {
      title: 'Ipsos mappings',
      key: 'IPSOS_MAPPINGS',
      active: false,
    },
    {
      title: 'External Buyer mappings',
      key: 'EXTERNAL_BUYER_MAPPINGS',
      active: true,
    },
    {
      title: 'CRM Id',
      key: 'CRM_ID',
      active: true,
    },
    {
      title: 'Programmatic Direct Settings',
      key: 'PROGRAMMATIC DIRECT SETTINGS',
      active: true,
    },
    {
      title: 'Core mappings',
      key: 'CORE_MAPPINGS',
      active: true,
    },
    {
      title: 'Traffic Density File',
      key: 'TRAFFIC_DENSITY_FILE',
      active: true,
    },
    {
      title: 'DQ Settings',
      key: 'DATA_QUALITY_SETTINGS',
      active: true,
    },
    {
      title: 'Pureprice Settings',
      key: 'PUREPRICE_SETTINGS',
      active: true,
    },
    {
      title: 'Buyer Rate Card',
      key: 'BUYER_RATE_CARD',
      active: true,
    },
    {
      title: 'Unicorn Quotas',
      key: 'UNICORN_QUOTAS_SETTINGS',
      active: true,
    },
  ],
  EXTERNAL_BUYERS: [
    {
      name: 'FULL CIRCLE',
      id: '1',
      templatePath: '/assets/templates/full-circle-template.csv',
      key: '',
      downloadFileName: 'fciMappings.csv',
    },
    {
      name: 'CINT',
      id: '2',
      templatePath: '/assets/templates/cint-template.csv',
      key: 'cint_mapping',
      downloadFileName: 'cintMappings.csv',
    },
    {
      name: 'LUCID',
      id: '3',
      templatePath: '/assets/templates/lucid-template.csv',
      key: 'lucid_mapping',
      downloadFileName: 'lucidMappings.csv',
    },
    {
      name: 'TOLUNA',
      id: '4',
      templatePath: '/assets/templates/toluna-template.csv',
      key: 'toluna_mapping',
      downloadFileName: 'tolunaMappings.csv',
    },
    {
      name: 'DYNATA',
      id: '5',
      templatePath: '/assets/templates/dynata-template.csv',
      key: 'dynata_mapping',
      downloadFileName: 'dynataMappings.csv',
    },
    {
      name: 'KANTAR + CINT',
      id: '6',
      templatePath: '/assets/templates/kantar-cint-template.csv',
      key: 'kantar_cint_mapping',
      downloadFileName: 'kantarCintMappings.csv',
    },
    {
      name: 'FOCUSVISION',
      id: '7',
      templatePath: '/assets/templates/focusvision-template.csv',
      key: 'focusvision_mapping',
      downloadFileName: 'focusVisionMappings.csv',
    },
    {
      name: 'REP DATA',
      id: '9',
      templatePath: '/assets/templates/rep-data-template.csv',
      key: 'rep_data_mapping',
      downloadFileName: 'repDataMappings.csv',
    },
    {
      name: 'PRODEGE',
      id: '10',
      templatePath: '/assets/templates/prodege-template.csv',
      key: 'prodege_mapping',
      downloadFileName: 'prodegeMappings.csv',
    },
    {
      name: 'MORNING_CONSULT',
      id: '11',
      templatePath: '/assets/templates/morning-consult-template.csv',
      key: 'morning_consult_mapping',
      downloadFileName: 'morningConsult.csv',
    },
    {
      name: 'MARKET_CUBE',
      id: '12',
      templatePath: '/assets/templates/market-cube-template.csv',
      key: 'market_cube_mapping',
      downloadFileName: 'marketCube.csv',
    },
    {
      name: 'PANELAND',
      id: '13',
      templatePath: '/assets/templates/paneland-template.csv',
      key: 'paneland_mapping',
      downloadFileName: 'paneland.csv',
    },
    {
      name: 'INNOVATE',
      id: '14',
      templatePath: '/assets/templates/innovate-template.csv',
      key: 'innovate_mapping',
      downloadFileName: 'innovate.csv',
    },
    {
      name: 'IPSOS',
      id: '15',
      templatePath: '/assets/templates/ipsos-template.csv',
      key: 'ipsos_mapping',
      downloadFileName: 'ipsos.csv',
    },
  ],

  LEGACY_REGIONS_TO_HIDE: { IN: [224, 225, 227, 228, 231] },
  CENSUS_QUAL_IDS: [
    211, 212, 213, 214, 215, 216, 223, 224, 225, 226, 227, 228, 231, 245, 244,
  ],
  CENSUS_REP_ID: 246,
  IPSOS: {
    name: 'IPSOS',
    templatePath: '/assets/templates/ipsos-template.csv',
    key: 'ipsos_mapping',
    downloadFileName: 'ipsosMappings.csv',
  },
  PUREPRICE_RATECARD: {
    name: 'PUREPRICE_RATECARD',
    templatePath: '/assets/templates/pureprice-ratecard-template.csv',
    key: 'pureprice_ratecard',
    downloadFileName: 'pureprice-ratecard-template.csv',
  },
  PUREPRICE_FIELDTIME_MULTIPLIER: {
    name: 'PUREPRICE_FIELDTIME_MULTIPLIER',
    templatePath:
      '/assets/templates/pureprice-fieldtime-multiplier-template.csv',
    key: 'pureprice_fieldtime_multiplier',
    downloadFileName: 'pureprice-fieldtime-multiplier-template.csv',
  },
  BUYER_RATE_CARD: {
    name: 'BUYER_RATE_CARD',
    templatePath: '/assets/templates/buyer-ratecard-template.csv',
    key: 'buyer_rate_card',
    downloadFileName: 'buyer-rate-card-template.csv',
  },
  PREVALENCE_MULTIPLIER: {
    name: 'PREVALENCE_MULTIPLIER',
    templatePath: '/assets/templates/prevalence-multiplier-template.csv',
    key: 'prevalence_multiplier',
    downloadFileName: 'prevalence-multiplier-template.csv',
  },
  UNICORN_QUOTAS_SETTINGS: {
    name: 'UNICORN_QUOTAS_SETTINGS',
    templatePath: '/assets/templates/unicorn-quotas-template.xlsx',
    key: 'unicorn_quotas_settings',
    downloadFileName: 'unicorn-quotas-template.xlsx',
  },
  COUNTRY_QUAL_ID: 99999,
  DEFAULT_PRICE_MULTIPLIER: 1,
  DEFAULT_BASE_MULTIPLIER: 0.02,
  SAMPLIFY: {
    name: 'SAMPLIFY',
    templatePath: '/assets/templates/samplifyMappings.csv',
    downloadFileName: 'samplifyMappings.csv',
  },
  CRM_ID: {
    VALID_FILE_EXTENSIONS: ['.csv', '.xlsx', '.xls'],
    templatePath: '/assets/templates/crm-id-template.csv',
  },
  FILES_FILTER_LIST: ['.xls', '.xlsx', '.csv'],
  ENGLISH_LANGUAGE: 'eng',
  NO_QUALIFICATION_ERROR_MESSAGE: 'Qualification id does not exists',
  CONFIRM_MODAL_TEXT: {
    UPLOAD_UNICORN:
      'You are uploading a new set of impossible quotas, please make sure the file has all quotas previously uploaded. This operation will remove any existing impossible quota and add the new set.\n\nDo you want to proceed and upload the new set of impossible quotas?',
  },
  DEFAULT_ACTIVITY_LOG_EXPIRATION_TIME: 120, // 120 days,
  ALL_COUNTRIES: {
    id: 0,
    name: 'All',
    short_Code: 'ALL',
    pl_code: 'ALL',
    lang: [],
    orderId: 0,
  },
});
export const INVALID_PAGE_NUMBER = 'Invalid page number';
