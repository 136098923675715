import { Component, OnInit } from '@angular/core';
import {
  Qualification,
  GetGenpopDataResponse,
  CountryGenponData,
  ConditionCode,
  GetEngQualificationTextResponse,
} from './genpop-setting.interface';
import {
  EachCountry,
  EachLanguage,
} from '../../shared/interfaces/create-surveys.interface';
import { GenpopSettingService } from './genpop-setting.service';
import { CommonService } from '../../shared/services/common-service/common-service';
import { ModalService } from '@purespectrum1/ui/modal';
import { GenpopFileuploadModalComponent } from './genpop-fileupload-modal/genpop-fileupload-modal.component';
import { Constants } from '../operator-settings-constants';
import { ToasterService } from '@purespectrum1/ui';
import { AuthService } from '../../auth/auth.service';
import { UserType } from '../../constants/global';

@Component({
  selector: 'ps-genpop-settings',
  templateUrl: './genpop-settings.component.html',
  styleUrls: ['./genpop-settings.component.css'],
})
export class GenpopSettingsComponent implements OnInit {
  constructor(
    private _genpopSettingService: GenpopSettingService,
    private _modal: ModalService,
    private _commonService: CommonService,
    private _toasterService: ToasterService,
    _authService: AuthService
  ) {
    this.adminAccess = _authService.user?.operatorAcssLvls === UserType.ADMIN;
  }

  countryList: Array<EachCountry> = [];
  selectedCountry?: EachCountry;
  listOfLanguages: Array<EachLanguage> = [];
  selectedLanguage?: EachLanguage;
  listOfQualifications: Array<Qualification> = [];
  selectedQualification?: Qualification;
  showTextQualification?: string;
  genpopList: Array<CountryGenponData> = [];
  showPercentageInput?: boolean;
  showMultiInput?: boolean;
  searchText: string = '';
  fileName: string = '';
  file!: File;
  baseMultiplier!: number;
  editBaseMultiplier: boolean = false;
  adminAccess: boolean = false;
  pageCounter: number[] = [];
  pageConfig: {
    current_page: number;
    page_limit: number;
    last_page: number;
    page_input: boolean;
    default_page_limit: number;
    pagination_ready: boolean;
    q_id: number | null;
  } = {
    current_page: 1,
    page_limit: 10,
    last_page: 1,
    page_input: false,
    default_page_limit: 10,
    pagination_ready: false,
    q_id: null,
  };

  ngOnInit(): void {
    this._getCoutries();
  }

  selectCountry = (event: EachCountry) => {
    this.selectedLanguage = undefined;
    this.selectedQualification = undefined;
    this.showTextQualification = undefined;
    this.listOfLanguages = event.lang;
    this.selectedCountry = event;
  };

  async selectLanguage(event: EachLanguage) {
    if (!this.selectedCountry) {
      return;
    }

    this.listOfQualifications = [];
    this.selectedLanguage = event;
    const locale = `${event.transalte_code}_${this.selectedCountry.short_Code}`;

    try {
      const initialResponse = await this._genpopSettingService
        .getQualifications(locale, 1)
        .toPromise();

      let totalPages = initialResponse.pages
        ? Number(initialResponse.pages)
        : 1;
      let qualifications = initialResponse.qualifications
        ? initialResponse.qualifications
        : [];

      if (totalPages > 1) {
        const pageRequests = [];
        for (let page = 2; page <= totalPages; page++) {
          pageRequests.push(
            this._genpopSettingService
              .getQualifications(locale, page)
              .toPromise()
          );
        }

        const pageResponses = await Promise.all(pageRequests);
        pageResponses.forEach((response) => {
          if (response.qualifications) {
            qualifications = qualifications.concat(response.qualifications);
          }
        });
      }

      this.listOfQualifications = qualifications;
    } catch (error) {
      this._toasterService.error('Error fetching qualifications');
    }
  }

  private _fetchGenpopData(page?: number): void {
    this._genpopSettingService
      .getGenpopDataByQualificationCode(
        this.selectedCountry?.short_Code || '',
        this.selectedLanguage?.short_code || '',
        this.selectedQualification?.qualification_code || 1,
        page || 1
      )
      .subscribe({
        // eslint-disable-next-line complexity
        next: (response: GetGenpopDataResponse[]) => {
          this.pageConfig.pagination_ready = false;

          if (response.length) {
            const pagination =
              response[0]?.pagination ?? response[0].data.pagination;
            if (!this.pageConfig.q_id) {
              this.pageConfig.q_id = response[0].q_id ?? null;
            } else if (this.pageConfig.q_id !== response[0].q_id) {
              this.pageCounter = [];
              this.pageConfig.page_limit =
                pagination > this.pageConfig.default_page_limit
                  ? this.pageConfig.default_page_limit
                  : pagination;
              this.pageConfig.q_id = response[0].q_id ?? null;
            }

            if (![0, 1].includes(pagination)) {
              this.pageConfig.last_page = pagination;
            }

            // Set page to 1 in case of qualification change
            if (!page) {
              this.pageConfig.current_page = 1;
            }

            if (this.pageConfig.last_page < this.pageConfig.page_limit) {
              this.pageConfig.page_limit = this.pageConfig.last_page;
            }

            this.pageCounter = Array.from(
              {
                length: this.pageConfig.page_limit,
              },
              (_, i) => i + 1
            );

            const genPopResponse =
              response[0].data[this.selectedCountry?.short_Code || ''][
                this.selectedLanguage?.short_code || ''
              ];
            this._setGenpopList(genPopResponse);
            const nonGenpopList = this._getNonGenpopList(genPopResponse);
            this.genpopList = [...this.genpopList, ...nonGenpopList];
            this.baseMultiplier = this.genpopList[0]?.base_multiplier;
          } else {
            // when no genpop data in db
            this.genpopList =
              this.selectedQualification?.condition_codes.reduce(
                (acc: any, conditionCodeItem: ConditionCode) => {
                  const tempItem =
                    this._transformConditionCodeIntoGenPopData(
                      conditionCodeItem
                    );
                  acc.push(tempItem);
                  return acc;
                },
                []
              );
            this.baseMultiplier = this.genpopList[0]?.base_multiplier;
          }
        },
        complete: () => {
          this.pageConfig.pagination_ready = true;
        },
      });
  }

  selectQualification = (event: Qualification) => {
    if (!this.selectedCountry || !this.selectedLanguage) {
      return;
    }
    this.selectedQualification = event;
    this.showTextQualification = `${this.selectedQualification.desc} ( ${this.selectedQualification.qualification_code} )`;
    this._fetchGenpopData();

    if (this.selectedLanguage.short_code !== Constants.ENGLISH_LANGUAGE) {
      this.genpopList.forEach((genpopItem) => {
        const match = event.condition_codes.find(
          (conditionItem) =>
            conditionItem.id.toString() === genpopItem.c_id.toString() &&
            conditionItem.text === genpopItem.c_name
        );
        if (match) {
          genpopItem.eng_text = match.eng_text;
        }
      });

      if (!this.genpopList[0]?.eng_text) {
        this._translateTextInEnglish(
          this.selectedCountry,
          event.qualification_code
        );
      }
    }
  };

  private _setGenpopList(genPopResponse: CountryGenponData[]) {
    this.genpopList = genPopResponse.map((item) => {
      item.price_multiplier =
        item.price_multiplier === null || isNaN(item.price_multiplier)
          ? Constants.DEFAULT_PRICE_MULTIPLIER
          : item.price_multiplier;
      item.base_multiplier =
        item.base_multiplier === null || isNaN(item.base_multiplier)
          ? Constants.DEFAULT_BASE_MULTIPLIER
          : item.base_multiplier;
      if (!this.selectedQualification?.condition_codes.length) {
        // for age, income no qualification codes exist
        return item;
      }
      const conditionCodeItem =
        this.selectedQualification?.condition_codes?.find(
          (conditionCode) =>
            conditionCode.id &&
            item.c_id &&
            conditionCode.id.toString() === item.c_id.toString()
        );
      if (conditionCodeItem) {
        item.c_name =
          conditionCodeItem.text ?? conditionCodeItem.name ?? item.c_name;
      }
      return item;
    });
  }

  private _getNonGenpopList(genPopResponse: CountryGenponData[]) {
    return this.selectedQualification?.condition_codes
      .filter(
        (code) =>
          !genPopResponse.some(
            (genPop) =>
              code.id &&
              genPop.c_id &&
              code.id.toString() === genPop.c_id.toString()
          )
      )
      .reduce((acc: any, conditionCodeItem: ConditionCode) => {
        if (conditionCodeItem.id) {
          const tempItem =
            this._transformConditionCodeIntoGenPopData(conditionCodeItem);
          acc.push(tempItem);
        }
        return acc;
      }, []);
  }

  private _translateTextInEnglish(
    selectedCountry: EachCountry,
    qualificationCode: number
  ) {
    this._genpopSettingService
      .getQualificationsEnglishText(
        selectedCountry.short_Code,
        qualificationCode
      )
      .subscribe(
        (responseGetEngQua: GetEngQualificationTextResponse) => {
          if (responseGetEngQua.msg && !responseGetEngQua.values) {
            this._toasterService.info(responseGetEngQua.msg);
          }
          this.genpopList = this.genpopList.map((item) => {
            return {
              ...item,
              label_name: this._getLabelForItem(
                item,
                responseGetEngQua,
                this.selectedCountry!.short_Code
              ),
            };
          });
          this.baseMultiplier = this.genpopList[0]?.base_multiplier;
        },
        (error) => {
          if (error.error.msg) {
            this._toasterService.error(error.error.msg);
          } else {
            this._toasterService.error(
              'Something went wrong! Please try again.'
            );
          }
        }
      );
  }

  private _getLabelForItem(
    item: CountryGenponData,
    responseGetEngQua: GetEngQualificationTextResponse,
    countryCode: string
  ) {
    if (
      responseGetEngQua &&
      responseGetEngQua.values &&
      responseGetEngQua.values[0] &&
      responseGetEngQua.values[0].data[countryCode] &&
      responseGetEngQua.values[0].data[countryCode].eng &&
      responseGetEngQua.values[0].data[countryCode].eng.find(
        (option) => option.id.toString() === item.c_id.toString()
      )?.name
    ) {
      return responseGetEngQua.values[0].data[countryCode].eng.find(
        (option) => option.id.toString() === item.c_id.toString()
      )?.name;
    }
    return item.c_name;
  }

  private _transformConditionCodeIntoGenPopData(
    item: ConditionCode
  ): CountryGenponData {
    return {
      c_id: Number(item.id),
      c_name: item.text ?? item.name,
      price_multiplier: Constants.DEFAULT_PRICE_MULTIPLIER,
      base_multiplier: Constants.DEFAULT_BASE_MULTIPLIER,
      perc: 0,
    };
  }

  uploadGenpopFile = (
    file: HTMLInputElement,
    isGlobalFile: boolean = false
  ) => {
    if (!file.files || !file.files.length) {
      return;
    }
    const fileToUpload: File = file.files[0];
    this._modal.open(GenpopFileuploadModalComponent, {
      data: {
        genpopSelectedQualificationName: this.selectedQualification?.desc,
        genpopSelectedCountry: this.selectedCountry?.name,
        genpopSelectedLanguage: this.selectedLanguage?.name,
        genpopGlobalFile: isGlobalFile,
        genpopFileToUpload: fileToUpload,
      },
      width: '24rem',
      height: '14rem',
    });
  };

  changeMultiValue = (
    price_multiplier_value: number,
    genpopData: CountryGenponData
  ) => {
    if (isNaN(price_multiplier_value)) {
      // empty values becomes NaN and defalut value 1 in case of NaN recieved
      price_multiplier_value = Constants.DEFAULT_PRICE_MULTIPLIER;
    }

    const indexToChange = this.genpopList.indexOf(genpopData);
    const payload = this._buildBasicPayload();
    if (!this.selectedLanguage || !this.selectedCountry || !payload) {
      return;
    }

    payload.data[this.selectedCountry.short_Code][
      this.selectedLanguage.short_code
    ][indexToChange]['price_multiplier'] = price_multiplier_value;

    if (price_multiplier_value !== 0) {
      this._genpopSettingService
        .updateGenpopDocument(
          this.selectedCountry.short_Code,
          this.selectedLanguage.short_code,
          payload
        )
        .subscribe((response) => {
          this.genpopList[indexToChange].price_multiplier =
            price_multiplier_value;
          this.genpopList[indexToChange].showEditMultiplier = false;
        });
    }
  };

  changePercentageValues = (
    percentageValue: number,
    genpopData: CountryGenponData
  ) => {
    ///
    if (isNaN(percentageValue)) {
      // empty values becomes NaN and defalut value 1 in case of NaN recieved
      percentageValue = Constants.DEFAULT_PRICE_MULTIPLIER;
    }

    const indexToChange = this.genpopList.indexOf(genpopData);
    const payload = this._buildBasicPayload();
    if (!this.selectedLanguage || !this.selectedCountry || !payload) {
      return;
    }

    payload.data[this.selectedCountry.short_Code][
      this.selectedLanguage.short_code
    ][indexToChange]['perc'] = percentageValue;

    if (genpopData.price_multiplier !== 0) {
      this._genpopSettingService
        .updateGenpopDocument(
          this.selectedCountry.short_Code,
          this.selectedLanguage.short_code,
          payload
        )
        .subscribe((response) => {
          this.genpopList[indexToChange].perc = percentageValue;
          this.genpopList[indexToChange].showEditPorcentage = false;
        });
    }
  };

  selectFile = (event: Event) => {
    const element = <HTMLInputElement>event.currentTarget;
    this.file = (element.files as FileList)[0];
    if (element.files !== null) {
      this.fileName = this.file?.name;
    }
  };

  public setCurrentPage = (page: number) => {
    if (!this.selectedCountry || !this.selectedLanguage) {
      return;
    }
    this.pageConfig.pagination_ready = false;
    this.pageConfig.current_page = page;
    this._fetchGenpopData(page);
  };

  public setPageInput = () => {
    this.pageConfig.page_input = !this.pageConfig.page_input;
  };

  public setPageByAction = (action: string) => {
    switch (action) {
      case 'back':
        if (this.pageConfig.current_page <= 1) {
          return;
        }
        this.pageConfig.current_page--;
        this.setCurrentPage(this.pageConfig.current_page);
        break;
      case 'next':
        if (this.pageConfig.current_page >= this.pageConfig.last_page) {
          return;
        }
        this.pageConfig.current_page++;
        this.setCurrentPage(this.pageConfig.current_page);
        break;
      default:
        break;
    }
  };

  private _getCoutries = () => {
    this._commonService.getCountries().subscribe((response) => {
      this.countryList = response;
    });
  };

  public updateBaseMultiplier() {
    if (isNaN(this.baseMultiplier)) {
      // empty values becomes NaN and defalut value 1 in case of NaN recieved
      this.baseMultiplier = Constants.DEFAULT_BASE_MULTIPLIER;
    }

    const payload = this._buildBasicPayload();
    if (!this.selectedLanguage || !this.selectedCountry || !payload) {
      return;
    }

    payload.data[this.selectedCountry.short_Code][
      this.selectedLanguage.short_code
    ] = payload.data[this.selectedCountry.short_Code][
      this.selectedLanguage.short_code
    ].map((qualification) => {
      qualification.base_multiplier = this.baseMultiplier;
      return qualification;
    });

    this._genpopSettingService
      .updateGenpopDocument(
        this.selectedCountry.short_Code,
        this.selectedLanguage.short_code,
        payload
      )
      .subscribe((response) => {
        this.genpopList.forEach((qualification) => {
          qualification.base_multiplier = this.baseMultiplier;
        });
        this.editBaseMultiplier = false;
      });
  }

  private _buildBasicPayload() {
    if (!this.selectedLanguage || !this.selectedCountry) {
      return null;
    }

    const payload: GetGenpopDataResponse = {
      q_id: this.selectedQualification?.qualification_code,
      name: this.selectedQualification?.desc,
      data: {
        [this.selectedCountry.short_Code]: {
          [this.selectedLanguage.short_code]: this._buildDataPayload(),
        },
      },
      pagination: this.pageCounter.length,
    };

    return payload;
  }

  private _buildDataPayload() {
    return this.genpopList.map((qualification) => {
      return {
        c_id: qualification.c_id,
        c_name: qualification.c_name,
        perc: qualification.perc,
        price_multiplier: qualification.price_multiplier,
        base_multiplier: qualification.base_multiplier,
      };
    });
  }

  public resetBaseMultiplier() {
    this.baseMultiplier = this.genpopList[0]?.base_multiplier;
    this.editBaseMultiplier = false;
  }
}
